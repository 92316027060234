/*!
 loadCSS: load a CSS file asynchronously.
 Based on work by:
 [c]2014 @scottjehl, Filament Group, Inc.
 Licensed MIT
 */
export default function loadCSS(name, href, before, media, loadedClass) {
	// Arguments explained:
	// `href` is the URL for your CSS file.
	// `before` optionally defines the element we'll use as a reference for injecting our <link>
	// By default, `before` uses the first <script> element in the page.
	// However, since the order in which stylesheets are referenced matters, you might need a more specific location in your document.
	// If so, pass a different reference element to the `before` argument and it'll insert before that instead
	// note: `insertBefore` is used instead of `appendChild`, for safety re: http://www.paulirish.com/2011/surefire-dom-element-insertion/

	const setClass = () => {
		if (loadedClass) {
			if (!document.documentElement || !document.documentElement.classList) {
				setTimeout(setClass, 0);
			} else {
				document.documentElement.classList.add(loadedClass);
			}
		}
	};

	// firefox has issues with the method valid for the others (it says that is loaded when the rendering is not finished),
	// we are using the variant explained here:
	// https://www.phpied.com/when-is-a-stylesheet-really-loaded/

	// NOTE: Will be altered again if not running into bugs in browsers other than firefox
	// const isFirefox = navigator.userAgent.indexOf('Firefox') > -1;
	const isAny = true;

	let style;
	if (isAny) {
		style = document.createElement('style');
		if (media) {
			style.media = media;
		}
		style.textContent = '@import "' + href + '";';


		const interval = setInterval(() => {
			try {
			  if (style.sheet.cssRules) {	// <--- MAGIC: only populated when file is loaded
				clearInterval(interval);
				setClass();
			  }
			} catch (e) {
			  // just loop again
			}
		  }, 0);
	} else {
		style = window.document.createElement('link');
		style.rel = 'stylesheet';
		style.href = href;
		// temporarily, set media to something non-matching to ensure it'll fetch without blocking render
		style.media = 'only x';
		const sheets = window.document.styleSheets;
		const interval = setInterval(() => {
			for (let i = 0; i < sheets.length; i++) {
				if (sheets[i].href && sheets[i].href.indexOf(href) > -1) {
					style.media = media || 'all';
					clearInterval(interval);
					setClass();
				}
			}
		}, 0);
	}
	const ref = before || window.document.getElementsByTagName('script')[0];
	ref.parentNode.insertBefore(style, ref);
	return style;
}
